/* global Ammo, Detector, THREE */
import Stats from './imported/dat.stats.module';
import { Sky } from './imported/sky';
import { Water } from './imported/water';
import { GUI } from './imported/dat.gui.module'
import { RenderMyStuff, LoadMyStuff } from './LoadAllStuff'
// const OrbitControls = require('three-orbit-controls')(THREE);
require('./imported/PointerLockControls')

if (typeof Ammo !== 'function') {
  window.location.reload();
}

var speed = 1;

Ammo().then(function(Ammo) {

  // Detects webgl
        if ( window.Detector && !window.Detector.webgl ) {
            Detector.addGetWebGLMessage();
            document.getElementById( 'container' ).innerHTML = "";
        }

        // - Global variables -

  // Heightfield parameters
        var terrainWidthExtents = 100;
        var terrainDepthExtents = 100;
        
        var terrainWidth = 128;
        var terrainDepth = 128;

        var realWorldTerrainWidth = 1024;
        var realWorldTerrainDepth = 1024;

        var terrainMaxHeight = 100;
        var terrainMinHeight = -100;

  // Graphics variables
        var container, stats;
        var camera, controls, scene, renderer;
        var clock = new THREE.Clock();

        // Physics variables
        var collisionConfiguration;
        var dispatcher;
        var broadphase;
        var solver;
        var physicsWorld;
        var groundShape;
        var sun, water;
        var terrainScene;
        var sand;
        var dirLight;

        var moveForward, moveBackward, moveLeft, moveRight, canJump, prevTime, raycaster;

        const velocity = new THREE.Vector3();
        const direction = new THREE.Vector3();

        var heightData = null;
        var ammoHeightData = null;

  // - Main code -
        init();
        animate();


        const positionToHeightMap = (x, z) => {
          // const xCorrected = Math.round((x + realWorldTerrainWidth / 2) / realWorldTerrainWidth * terrainWidth);
          // const zCorrected = Math.round((z + realWorldTerrainDepth / 2) / realWorldTerrainDepth * terrainDepth);


          // console.log(xCorrected, zCorrected)

          //   // camera.position.y = heightData[ terrainHalfWidth + terrainHalfDepth * terrainWidth ] * ( terrainMaxHeight - terrainMinHeight ) + 5;

          // console.log(terrainWidth /2, terrainDepth /2)

          // return heightData[Math.round(xCorrected + zCorrected * terrainWidth)];

          return 40;
        }

        function init() {

            heightData = generateHeight( terrainWidth, terrainDepth, terrainMinHeight, terrainMaxHeight );


            window.heightData = heightData;

            initGraphics();
            
            initPhysics();

            const canvas = document.getElementById('container').querySelector('canvas')
            

            var icosahedronGeometry = new THREE.IcosahedronGeometry(10, 4);
            var lambertMaterial = new THREE.MeshLambertMaterial({
                color: 0xffffff,
                wireframe: true
            });

            var dome = new THREE.Mesh(icosahedronGeometry, lambertMaterial);
            dome.position.set(0, 0, 0);
            dome.scale.set(65, 65, 65);

            canvas.addEventListener( 'click', function () {
              setTimeout(() => {
                console.log('Vet')
                effectController.turbidity = 0;
                effectController.azimuth = -20;
                effectController.elevation = 18;

                scene.add(dome)
                scene.add(dirLight)
                updateSun();
              }, 16000)
              setTimeout(() => {
                scene.remove(dirLight);
                dirLight.position.set( 100, 100, 5 );
              }, 15500)
              window.controls.lock();
            });

            LoadMyStuff(scene, renderer, camera);

            sun = new THREE.Vector3();

            // Water
          
            const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );
          
            water = new Water(
              waterGeometry,
              {
                textureWidth: 512,
                textureHeight: 512,
                waterNormals: new THREE.TextureLoader().load( 'textures/waternormals.jpg', function ( texture ) {
          
                  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          
                } ),
                sunDirection: new THREE.Vector3(),
                sunColor: 0xffffff,
                waterColor: 0x001e0f,
                distortionScale: 3.7,
                fog: scene.fog !== undefined
              }
            );
          
            water.rotation.x = - Math.PI / 2;
          
            scene.add( water );

            // Skybox

            const sky = new Sky();			
            sky.scale.setScalar( 450000 );
            scene.add( sky );

            const skyUniforms = sky.material.uniforms;

            skyUniforms[ 'turbidity' ].value = 10;
            skyUniforms[ 'rayleigh' ].value = 2;
            skyUniforms[ 'mieCoefficient' ].value = 0.005;
            skyUniforms[ 'mieDirectionalG' ].value = 0.8;

            const effectController = {
              turbidity: 2.6,
              rayleigh: 0.075,
              mieCoefficient: 0.016,
              mieDirectionalG: 0.2,
              elevation: 3.7,
              azimuth: 180,
              exposure: renderer.toneMappingExposure
            };

            // TERRAIN

            var blend;
            // Generate a terrain
             var loader = new THREE.TextureLoader();
              loader.load('textures/sand1.jpg', function(t1) {
                t1.wrapS = t1.wrapT = THREE.RepeatWrapping;
                sand = new THREE.Mesh(
                  new THREE.PlaneBufferGeometry(16384+1024, 16384+1024, 64, 64),
                  new THREE.MeshLambertMaterial({map: t1})
                );
                sand.position.y = -101;
                sand.rotation.x = -0.5 * Math.PI;
                scene.add(sand);
                loader.load('textures/grass1.jpg', function(t2) {
                  loader.load('textures/stone1.jpg', function(t3) {
                    loader.load('textures/snow1.jpg', function(t4) {
                      // t2.repeat.x = t2.repeat.y = 2;
                      blend = THREE.Terrain.generateBlendedMaterial([
                        {texture: t1},
                        {texture: t2, levels: [-80, -35, 20, 50]},
                        {texture: t3, levels: [20, 50, 60, 85]},
                        {texture: t4, glsl: '1.0 - smoothstep(65.0 + smoothstep(-256.0, 256.0, vPosition.x) * 10.0, 80.0, vPosition.z)'},
                        {texture: t3, glsl: 'slope > 0.7853981633974483 ? 0.2 : 1.0 - smoothstep(0.47123889803846897, 0.7853981633974483, slope) + 0.2'}, // between 27 and 45 degrees
                      ]);
                      Regenerate();
                    });
                  });
                });
              });
              
              const Regenerate = () => {
                var heightmapImage = new Image();
                heightmapImage.src = 'textures/heatmap.png';

                heightmapImage.onload = () => {
                  var xS = 63, yS = 63;
                  terrainScene = THREE.Terrain({
                      easing: THREE.Terrain.Linear,
                      heightmap: heightmapImage,
                      material: blend,
                      maxHeight: 100,
                      minHeight: -100,
                      steps: 1,
                      stretch: true,
                      xSegments: xS,
                      xSize: realWorldTerrainWidth,
                      ySegments: yS,
                      ySize: realWorldTerrainDepth,
                      turbulent: false,
                      
                  });
  
                  // Assuming you already have your global scene, add the terrain to it
                  scene.add(terrainScene);
                }
            }

            // const pmremGenerator = new THREE.PMREMGenerator( renderer );

            function updateSun(a,b) {

              const uniforms = sky.material.uniforms;
              uniforms[ 'turbidity' ].value = effectController.turbidity;
              uniforms[ 'rayleigh' ].value = effectController.rayleigh;
              uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
              uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;
    
              const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
              const theta = THREE.MathUtils.degToRad( effectController.azimuth );
    
              sun.setFromSphericalCoords( 1, phi, theta );
    
              uniforms[ 'sunPosition' ].value.copy( sun );
    
              renderer.toneMappingExposure = effectController.exposure;
              // renderer.render( scene, camera );

            }
            const gui = new GUI();

            gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( updateSun );
            gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( updateSun );
            gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( updateSun );
            gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( updateSun );
            gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( updateSun );
            gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( updateSun );
            gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( updateSun );
    

            updateSun();

        }

        function initGraphics() {

           container = document.getElementById( 'container' );

            camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1000 );

            scene = new THREE.Scene();

            // camera.position.y = heightData[ terrainHalfWidth + terrainHalfDepth * terrainWidth ] * ( terrainMaxHeight - terrainMinHeight ) + 5;
            camera.position.y = 40

            camera.position.z =  terrainDepthExtents / 2;
            camera.lookAt( new THREE.Vector3( 0, 50, -200 ) );
            scene.fog = new THREE.Fog( 0xffffff, 0, 2000 );

            controls = new THREE.PointerLockControls( camera, document.body );
            raycaster = new THREE.Raycaster( new THREE.Vector3(), new THREE.Vector3( 0, - 1, 0 ), 0, 10 );

            window.controls = controls;

            controls.movementSpeed = 70;
            controls.lookSpeed = 0.1;
            controls.noFly = true;
            controls.lookVertical = false;
            
				    scene.add( controls.getObject() );

            const onKeyDown = function ( event ) {

              switch ( event.code ) {
    
                case 'ArrowUp':
                case 'KeyW':
                  moveForward = true;
                  break;
    
                case 'ArrowLeft':
                case 'KeyA':
                  moveLeft = true; 
                  break;
    
                case 'ArrowDown':
                case 'KeyS':
                  moveBackward = true;
                  break;
    
                case 'ArrowRight':
                case 'KeyD':
                  moveRight = true;
                  break;
                case 'ShiftLeft':
                  speed = .5;
                  break;
    
                case 'Space':
                  if ( canJump === true ) velocity.y += 150;
                  canJump = false;
                  break;
                default:
                  break;
              }
    
            };
    
            const onKeyUp = function ( event ) {
    
              switch ( event.code ) {
    
                case 'ArrowUp':
                case 'KeyW':
                  moveForward = false;
                  break;
    
                case 'ArrowLeft':
                case 'KeyA':
                  moveLeft = false;
                  break;
    
                case 'ArrowDown':
                case 'KeyS':
                  moveBackward = false;
                  break;
                case 'ShiftLeft':
                  speed = 1;
                  break;
    
                case 'ArrowRight':
                case 'KeyD':
                  moveRight = false;
                  break;
                default:
                  break;
              }
    
            };

            
            document.addEventListener( 'keydown', onKeyDown );
            document.addEventListener( 'keyup', onKeyUp );


            var geometry = new THREE.PlaneBufferGeometry( 100, 100, terrainWidth - 1, terrainDepth - 1 );
            geometry.rotateX( - Math.PI / 2 );

            var vertices = geometry.attributes.position.array;

            for ( var i = 0, j = 0, l = vertices.length; i < l; i ++, j += 3 ) {

      // j + 1 because it is the y component that we modify
                vertices[ j + 1 ] = heightData[ i ];

            }

            geometry.computeVertexNormals();

            dirLight = new THREE.DirectionalLight( 0xffffff, 1 );
            dirLight.position.set( 10, 10, 5 );
            scene.add( dirLight );

            renderer = new THREE.WebGLRenderer();
            renderer.setClearColor( 0xbfd1e5 );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( window.innerWidth, window.innerHeight );

            container.innerHTML = "";

            container.appendChild( renderer.domElement );

            stats = new Stats();
            stats.domElement.style.position = 'absolute';
            stats.domElement.style.top = '0px';
            container.appendChild( stats.domElement );

            //

            window.addEventListener( 'resize', onWindowResize, false );

        }

        function onWindowResize() {

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize( window.innerWidth, window.innerHeight );

        }

  function initPhysics() {

    // Physics configuration

    collisionConfiguration = new Ammo.btDefaultCollisionConfiguration();
    dispatcher = new Ammo.btCollisionDispatcher( collisionConfiguration );
    broadphase = new Ammo.btDbvtBroadphase();
    solver = new Ammo.btSequentialImpulseConstraintSolver();
    physicsWorld = new Ammo.btDiscreteDynamicsWorld( dispatcher, broadphase, solver, collisionConfiguration );
    physicsWorld.setGravity( new Ammo.btVector3( 0, - 6, 0 ) );

    // Create the terrain body

    groundShape = createTerrainShape( heightData );
    var groundTransform = new Ammo.btTransform();
    groundTransform.setIdentity();
      // Shifts the terrain, since bullet re-centers it on its bounding box.
    groundTransform.setOrigin( new Ammo.btVector3( 0, ( terrainMaxHeight + terrainMinHeight ) / 2, 0 ) );
    var groundMass = 0;
    var groundLocalInertia = new Ammo.btVector3( 0, 0, 0 );
    var groundMotionState = new Ammo.btDefaultMotionState( groundTransform );
    var groundBody = new Ammo.btRigidBody( new Ammo.btRigidBodyConstructionInfo( groundMass, groundMotionState, groundShape, groundLocalInertia ) );
    physicsWorld.addRigidBody( groundBody );

        }

        function generateHeight( width, depth, minHeight, maxHeight ) {

          // Generates the height data (a sinus wave)

            var size = width * depth;
            var data = new Float32Array( size );

            var hRange = maxHeight - minHeight;
            var w2 = width / 2;
            var d2 = depth / 2;
            var phaseMult = 12;

            var p = 0;
            for ( var j = 0; j < depth; j ++ ) {
              for ( var i = 0; i < width; i ++ ) {

                var radius = Math.sqrt(
                  Math.pow( ( i - w2 ) / w2, 2.0 ) +
                  Math.pow( ( j - d2 ) / d2, 2.0 ) );

                  var height = ( Math.sin( radius * phaseMult ) + 1 ) * 0.5  * hRange + minHeight;

                  data[ p ] = height;

                  p++;
                }
            }

            return data;

        }

        function createTerrainShape() {

            // This parameter is not really used, since we are using PHY_FLOAT height data type and hence it is ignored
            var heightScale = 1;

            // Up axis = 0 for X, 1 for Y, 2 for Z. Normally 1 = Y is used.
            var upAxis = 1;

            // hdt, height data type. "PHY_FLOAT" is used. Possible values are "PHY_FLOAT", "PHY_UCHAR", "PHY_SHORT"
            var hdt = "PHY_FLOAT";

            // Set this to your needs (inverts the triangles)
            var flipQuadEdges = false;

            // Creates height data buffer in Ammo heap
            ammoHeightData = Ammo._malloc( 4 * terrainWidth * terrainDepth );

            // Copy the javascript height data array to the Ammo one.
            var p = 0;
            var p2 = 0;
            for ( var j = 0; j < terrainDepth; j ++ ) {
              for ( var i = 0; i < terrainWidth; i ++ ) {

                // write 32-bit float data to memory
                Ammo.HEAPF32[ammoHeightData + p2 >> 2] = heightData[ p ];

                p ++;

                // 4 bytes/float
                p2 += 4;
              }
            }

          // Creates the heightfield physics shape
          var heightFieldShape = new Ammo.btHeightfieldTerrainShape(

            terrainWidth,
            terrainDepth,

            ammoHeightData,

            heightScale,
            terrainMinHeight,
            terrainMaxHeight,

            upAxis,
            hdt,
            flipQuadEdges
          );

          // Set horizontal scale
          var scaleX = terrainWidthExtents / ( terrainWidth - 1 );
          var scaleZ = terrainDepthExtents / ( terrainDepth - 1 );
          heightFieldShape.setLocalScaling( new Ammo.btVector3( scaleX, 1, scaleZ ) );

          heightFieldShape.setMargin( 0.05 );

          return heightFieldShape;

        }

        function animate() {

            requestAnimationFrame( animate );

            render();
            stats.update();

        }

        function render() {

            var deltaTime = clock.getDelta();
            const time = performance.now();

            updatePhysics( deltaTime );

            // controls.update( deltaTime );

            renderer.render( scene, camera );

            water.material.uniforms[ 'time' ].value += 1.0 / 180.0;

            if ( controls.isLocked === true ) {

              // raycaster.ray.origin.copy( controls.getObject().position );
              // raycaster.ray.origin.y += 50;
    
              // const intersections = raycaster.intersectObjects( scene.children );
    
              const onObject = false;
    
              const delta = ( time - prevTime ) / 1000;
    
              velocity.x -= velocity.x * 10.0 * delta * speed;
              velocity.z -= velocity.z * 10.0 * delta * speed;
    
              velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass
    
              direction.z = (moveForward ? 1 : 0) - (moveBackward ? 1 : 0);
              direction.x = ( moveRight ? 1 : 0 ) - ( moveLeft ? 1 : 0 );
              direction.normalize(); // this ensures consistent movements in all directions
    
              if ( moveForward || moveBackward ) velocity.z -= direction.z * 400.0 * delta;
              if ( moveLeft || moveRight ) velocity.x -= direction.x * 400.0 * delta;

             if ( onObject === true ) {
  
                velocity.y = Math.max( 0, velocity.y );
                canJump = true;
    
              }
              controls.moveRight( - velocity.x * delta );
              controls.moveForward( - velocity.z * delta );
    
              controls.getObject().position.y += ( velocity.y * delta ); // new behavior

              // const terrainHeight = heightData[ Math.round(controls.getObject().position.x + controls.getObject().position.z * terrainWidth) ]


              const terrainHeight = positionToHeightMap(controls.getObject().position.x, controls.getObject().position.z);
              // console.log(terrainHeight)

              if ( controls.getObject().position.y < terrainHeight ) {
    
                velocity.y = 0;
                controls.getObject().position.y = terrainHeight;
    
                canJump = true;
    
              }
    
            }

            
            RenderMyStuff(scene, renderer, camera);
				    prevTime = time;
        }

        function updatePhysics( deltaTime ) {

        physicsWorld.stepSimulation( deltaTime, 10 );
  }

});


function App() {
  return (
    <div>

    </div>
  );
}

export default App;
