/* global THREE */
import { STLLoader } from './imported/STLloader'
import html2canvas from 'html2canvas'
import {GLTFLoader} from './imported/GLFTLoader'
import { SERVER_URL } from './api';
import { io } from "socket.io-client";

var AudioContext = window.AudioContext          // Default
              || window.webkitAudioContext;  // Safari and old versions of Chrome

var SimplexNoise = require('simplex-noise')

var currentObjectsInSight = [];
const websiteMeshes = []
const hoverMeshes = []
const _websiteLinks = ['https://shoots.games', 'https://chrome.google.com/webstore/detail/marktplaats-anti-scam/pcbafmkiocdojcghmhdmmpjlebcehodn', 'https://jildertvenema.github.io/spotify-friends-app/',
'https://boggle.jildert.dev/', 'https://jildertvenema.github.io/whatsapp/', 'https://chat.jildert.dev/', 'https://abgewaschen.jildert.dev/', 'https://www.beenen.nl/augmented-reality-bediening/', 'https://github.com/jildertvenema/IDP', 'https://csb-tflhc.netlify.app/']

const websiteLinks = [];
var previousLink;

var players = [];
var myId = Math.random().toString(36).substring(7);
var socket;

var analyser, ball, dataArray;
var regionName;

try {
//     fetch('https://whatismyipaddress.com').then(res => res.text().then(txt => {
//         let cityname = txt.substring(txt.indexOf('<span>City:</span>'))
//         cityname = cityname.substring(0, cityname.indexOf('</span></p')).split('<span>').at(-1)
//         regionName = cityname
// }))
regionName = 'bassie';

} catch {
    regionName = 'Niks'
}


const loadStl = (filepath, x,y,z, scale, callback, color) => {
    const loader = new STLLoader();
    loader.load( filepath, function ( geometry ) {

        const material = new THREE.MeshPhysicalMaterial({
            color: color,
            // envMap: envTexture,
            metalness: .8,
            roughness: 0.8,
            transparent: true,
            transmission: 1.0,
            side: THREE.DoubleSide,
            clearcoat: 1.0,
            clearcoatRoughness: .8
        });
        const mesh = new THREE.Mesh( geometry, material );

        mesh.position.set(x,y,z)
        mesh.rotation.set(-Math.PI / 2, 0, -Math.PI/2)
        mesh.scale.set( scale,scale,scale );

        window.jildert = mesh;

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        callback(mesh);

    } );

}


const loadHTMLElementIntoMesh = async (element, height) => {

    // Array.from(document.getElementsByTagName('img')).forEach(image => {
    //     image.remove()
    // })

    const canvas = await html2canvas(element)
    var material = new THREE.MeshBasicMaterial();

    // document.body.appendChild(canvas)
    material.map = new THREE.CanvasTexture( canvas );
    
    var geometry = new THREE.BoxGeometry( 500, height || 470, 10 );
    var mesh = new THREE.Mesh( geometry, material );

    return { mesh, geometry }
}

const loadProjects = (scene, renderer, camera) => {

    const crosshair = document.getElementById('crosshair');
    crosshair.remove();

    const websiteCapture = document.getElementById('capture');
    websiteCapture.style.display = 'block'

    const projects = Array.from(document.getElementsByClassName('project-render'));
    let projectCount = projects.length;

    window.addEventListener("click", onclick, true);
    function onclick(event) {
        if (currentObjectsInSight.length > 0) {
            const index = websiteMeshes.indexOf(currentObjectsInSight[0].object);

            if (previousLink !== websiteLinks[index]) {
                window.open(websiteLinks[index], '_blank').focus();
            }
            previousLink = websiteLinks[index];
        }
    }

    
    loadHTMLElementIntoMesh(document.getElementById('3d-printer'), 750).then(({ mesh }) => {
        mesh.position.set(-35, 40, -10)
        mesh.scale.set(.015, .015, .015)
        scene.add(mesh);
    })


    projects.forEach((element, i) => {
        loadHTMLElementIntoMesh(element).then(({ mesh, geometry }) => {

            const position = [projectCount * 6, 40, 0]
            const scale = [.01, .01, .01]

            mesh.position.set(...position)
            mesh.scale.set(...scale)
        
            const hoverMaterial = new THREE.MeshPhongMaterial( {
                color: 0xb109b1,
                opacity: 0,
                transparent: true,
            } );

            var hoverMesh = new THREE.Mesh( geometry, hoverMaterial );

            hoverMesh.position.set(...position)
            hoverMesh.scale.set(...scale.map(x => x * 1.2))

            scene.add( mesh );
            scene.add(hoverMesh);
            websiteLinks.push(_websiteLinks[i])

            websiteMeshes.push(mesh)
            hoverMeshes.push(hoverMesh)

            projectCount--;

            if (projectCount <= 0) {
                websiteCapture.style.display = 'none';
                document.body.appendChild(crosshair);
            }
        });
    })    
}

const loader = new THREE.FontLoader();
const getTextMesh = (scene, text, x, y, z, scale, callback, color) => {

    loader.load( 'fonts/helvetiker_regular.typeface.json', function ( font ) {
        const textGeo = new THREE.TextGeometry( text, {
            font: font,
            size: 80,
            height: 5,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 10,
            bevelSize: 8,
            bevelOffset: 0,
            bevelSegments: 5
        } );

        textGeo.computeBoundingBox();
        var materials = [
            new THREE.MeshPhongMaterial( { color: color || 0xaf00af, flatShading: true } ), // front
            new THREE.MeshPhongMaterial( { color: color || 0xaf00af } ) // side
        ];

        var textMesh = new THREE.Mesh( textGeo, materials );

        textMesh.position.set(x,y,z)
        textMesh.scale.set(scale,scale,scale)

        callback(textMesh)
    } );
}

const loadText = (scene, text, x, y, z, scale) => {
    getTextMesh(scene, text, x, y, z, scale, (mesh) => {
        scene.add(mesh)
    })
}

const createTable = (scene, x,y,z) => {

    var group = new THREE.Group();
    for (let i = 0; i < 4; i ++) {

        const geometry = new THREE.BoxGeometry( .75, 10, .75 );

        const material = new THREE.MeshPhongMaterial( {color: 0x323232} );
        const leg = new THREE.Mesh( geometry, material );

        if (i <= 1) {
            leg.position.set(i * 9, -2, 0)
        } else {
            leg.position.set((i-2) * 9, -2, 9)
        }
        group.add(leg)
    }

    const geometry = new THREE.BoxGeometry( 9.5, .75, 9.5 );

    const material = new THREE.MeshPhongMaterial( {color: 0x323232} );
    const top = new THREE.Mesh( geometry, material );
    top.position.set(4.6, 2.7, 4.6)

    group.add(top)

    group.position.set(x,y,z);
    scene.add( group );
}


const loadPlayer = (scene, headText, id) => {
    const loader = new GLTFLoader();

    const player = {
        id
    }

    players.push(player);

    loader.load( 'RobotExpressive.glb', function ( gltf ) {

        var model = gltf.scene;

        const scale = 2.6
        model.scale.set(scale,scale,scale)

        const mixer = new THREE.AnimationMixer( model );

        const clip = gltf.animations.find(animation => animation.name === 'Running');

        const action = mixer.clipAction( clip );

        action.loop = THREE.LoopRepeat;

        action.play();

        console.log(gltf.animations)
        scene.add( model );

        var group = new THREE.Group()

        group.position.set(-50, 31, -50)

        group.add(model)

        var randomColor = new THREE.Color('#' + Math.floor(Math.random() * 16777215).toString(16));

        getTextMesh(scene, headText, 0, 0, 0, .02, (textMesh) => {
            var width = new THREE.Box3().setFromObject( textMesh ).max.x;

            var pivot = new THREE.Object3D();
            pivot.add(textMesh);
            textMesh.position.set(width/2*-1, 13.2, 0)

            group.add(pivot);
            player.textMesh = pivot;
        }, randomColor)

        scene.add(group)

        player.model = model;
        player.group = group;
        player.mixer = mixer;
        player.animations = gltf.animations;
        player.currentActive = action;
        player.currentActive.name = 'Running';
        player.animationBounce = 0;

    }, undefined, function ( e ) {

        console.error( e );

    } );
}

const load = (scene, renderer, camera) => {
    loadStl('jildert.stl', -200, 100, -640, 5, (mesh) => {
        scene.add(mesh)

        let y = 0;
        setInterval(() => {
            y+=.001;
            mesh.rotation.set(-Math.PI / 2, 0, -Math.PI/2 + y)
        }, 10)
    }, 0xb2ffc8)

    loadStl('ilse.stl', 200, -24, -640, 2.5, (mesh) => {
        scene.add(mesh)

        let y = 0;
        setInterval(() => {
            y-=.001;
            mesh.rotation.set(-Math.PI / 2, 0, -Math.PI*2 + y)
        }, 10)
    }, 0xb2ffc8)

    loadStl('ender3.stl', -50, 38.4, -10, .02, mesh => {
      
        mesh.rotation.set(-Math.PI / 2, 0, -Math.PI*2)
        scene.add(mesh)

    }, 0x7f7f7f)

    createTable(scene, -52, 34, -17);
    createTable(scene, -52, 44, -17);
    loadProjects(scene, renderer, camera)
    loadText(scene, 'My Programming Projects', 5, 45, 0, .04)
    loadText(scene, 'My 3D printer hobby', -60, 50, -10, .04)

    socket = io(SERVER_URL);

    const setActiveAction = (name, loadedPlayer, loop) => {

        if (loadedPlayer.currentActive.name === name) {
            return;
        }

        const clip = loadedPlayer.animations.find(animation => animation.name === name);
        const action = loadedPlayer.mixer.clipAction( clip );
        action.loop = loop || THREE.LoopRepeat;

        // loadedPlayer.mixer._actions[0] = clip
        loadedPlayer.mixer._actions[0].paused = true;
        loadedPlayer.mixer._actions[0].enabled = false;
        loadedPlayer.currentActive.fadeOut(0.2)
        action.reset();
        action.setEffectiveTimeScale( 1 )
        action.setEffectiveWeight( 1 )
        action.play();
        action.clampWhenFinished = true;
        action.fadeIn(0.2)
        loadedPlayer.currentActive = action;
        loadedPlayer.currentActive.name = name;
    }

    socket.on('message', (data) => {
        if (Array.isArray(data)) {

            data = data.filter(player => player.id !== myId);

            data.forEach(player => {
                const loadedPlayer = players.find(loadedplayer => loadedplayer.id === player.id)
                if (!loadedPlayer) {
                    loadPlayer(scene, player.regionName, player.id)
                } else {
                    const pos = player.position;
                    const rot = player.rotation;

                    if (loadedPlayer.model && pos && rot) {

                        pos.y -= 8;

                        const distance = loadedPlayer.group.position.distanceTo(pos);
                        
                        loadedPlayer.group.position.set(pos.x, pos.y, pos.z)
                        loadedPlayer.model.rotation.set(0,rot, 0)

                        if (pos.y > 32) {
                            setActiveAction('Jump', loadedPlayer)
                        }
                        else if (distance > 0.1) {
                            loadedPlayer.animationBounce = 0;
                            setActiveAction('Running', loadedPlayer)
                        } else {
                            loadedPlayer.animationBounce++;

                            if (loadedPlayer.animationBounce > 300) {
                                setActiveAction('Death', loadedPlayer, THREE.LoopOnce)
                                loadedPlayer.animationBounce = 300;
                            }
                            else if (loadedPlayer.animationBounce > 100) {
                                setActiveAction('Sitting', loadedPlayer, THREE.LoopOnce)
                            }
                            else if (loadedPlayer.animationBounce > 10) {
                                setActiveAction('Idle', loadedPlayer)
                            }
                        }
                    }
                }

            })

            players.forEach(loadedPlayer => {
                if (!data.find(player => player.id === loadedPlayer.id)) {
                    scene.remove(loadedPlayer.model)

                    const index = players.indexOf(loadedPlayer);

                    if (index > -1) {
                        players.splice(index, 1);
                    }

                }
            })
            
        }

    })

    var icosahedronGeometry = new THREE.IcosahedronGeometry(10, 4);
    var lambertMaterial = new THREE.MeshLambertMaterial({
        color: 0xff00ee,
        wireframe: true
    });

    ball = new THREE.Mesh(icosahedronGeometry, lambertMaterial);
    ball.position.set(-20, 160, -640);
    ball.scale.set(4.4,4.4,4.4);

    scene.add(ball)

    document.body.onclick = () => {
        if (!analyser && AudioContext) configureAudio();
    } 
}



const configureAudio = () => {
    var audio = new Audio('music.mp3')
    // get the audio file form the possible array of files, the user 
    // uploaded
    // load the file, and then play it - all using HTML5 audio element's // API
    audio.load();
    audio.play();

    var context = new AudioContext();  // create context
    var src = context.createMediaElementSource(audio); //create src inside ctx
    analyser = context.createAnalyser(); //create analyser in ctx
    src.connect(analyser);         //connect analyser node to the src
    analyser.connect(context.destination); // connect the destination 
                                        // node to the analyser

    analyser.fftSize = 512;
    var bufferLength = analyser.frequencyBinCount;
    dataArray = new Uint8Array(bufferLength);
}

var clock = new THREE.Clock();
const render = (scene, renderer, camera) => {
    var raycaster = new THREE.Raycaster();
    var mouse = new THREE.Vector2();
    raycaster.setFromCamera(mouse, camera);
    currentObjectsInSight = raycaster.intersectObjects(websiteMeshes, true); //array

    for (let i = 0; i < websiteMeshes.length; i++) {
        if (currentObjectsInSight.find(hit => hit.object === websiteMeshes[i])) {
            hoverMeshes[i].material.opacity = 0.2;
        } else {
            hoverMeshes[i].material.opacity = 0;
        }
    }

    currentObjectsInSight.forEach(hit => {
        const index = websiteMeshes.indexOf(hit.object);
        scene.add(hoverMeshes[index])
    })
    
    var delta = 0.75 * clock.getDelta();

    players.forEach(({ mixer, isWalking, textMesh }) => {
        mixer && mixer.update(delta)
        textMesh && textMesh.lookAt(camera.position.x, 30, camera.position.z)
    })

    if (socket && socket.connected && regionName) {
        const vec = new THREE.Vector3();
        camera.getWorldDirection( vec );
        const theta = Math.atan2(vec.x,vec.z);

        socket.emit('message', {
            position: camera.position,
            rotation: theta,
            regionName: regionName,
            id: myId
        })
    }

    if (analyser) {
        renderMusicBall();
    }
}

const renderMusicBall = () => {
    analyser.getByteFrequencyData(dataArray);

    var lowerHalfArray = dataArray.slice(0, (dataArray.length/2) - 1);
    var upperHalfArray = dataArray.slice((dataArray.length/2) - 1, dataArray.length - 1);

    var lowerMax = max(lowerHalfArray);
    var upperAvg = avg(upperHalfArray);
    
    var upperAvgFr = upperAvg / upperHalfArray.length;
    var lowerMaxFr = lowerMax / lowerHalfArray.length;

    makeRoughBall(ball, modulate(Math.pow(lowerMaxFr, 0.8), 0, 1, 0, 8), modulate(upperAvgFr, 0, 1, 0, 4));
}


var simplex = new SimplexNoise(Math.random)
function makeRoughBall(mesh, bassFr, treFr) {

    const position = mesh.geometry.attributes.position;
    var vector = new THREE.Vector3();


    for ( let i = 0; i < position.array.length; i +=3 ){

        vector = new THREE.Vector3(position.array[i], position.array[i+1], position.array[i+2])

        var offset = mesh.geometry.parameters.radius;
        var amp = 7;
        var time = window.performance.now();
        vector.normalize();
        var rf = 0.00001;
        var distance = (offset + bassFr ) + simplex.noise3D(vector.x + time *rf*7, vector.y +  time*rf*8, vector.z + time*rf*9) * amp * treFr;
        vector.multiplyScalar(distance);

        position.array[i] = vector.x
        position.array[i+1] = vector.y
        position.array[i+2]= vector.z
     }

    mesh.geometry.attributes.position.needsUpdate = true;
    // mesh.geometry.verticesNeedUpdate = true;
    mesh.geometry.normalsNeedUpdate = true;
    mesh.geometry.computeVertexNormals();
    mesh.geometry.computeFaceNormals();
    
  }

//some helper functions here
function fractionate(val, minVal, maxVal) {
    return (val - minVal)/(maxVal - minVal);
}

function modulate(val, minVal, maxVal, outMin, outMax) {
    var fr = fractionate(val, minVal, maxVal);
    var delta = outMax - outMin;
    return outMin + (fr * delta);
}

function avg(arr){
    var total = arr.reduce(function(sum, b) { return sum + b; });
    return (total / arr.length);
}

function max(arr){
    return arr.reduce(function(a, b){ return Math.max(a, b); })
}

const LoadMyStuff = load;
const RenderMyStuff = render;

export { RenderMyStuff, LoadMyStuff }